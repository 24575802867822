<template>
  <div class="detail">
    <img src="../assets/detail/detail1.png" alt="" />
    <div class="tab">
      <div class="item" :class="tabActive == index?'active':'no-active'" v-for="(item,index) in tabList" :key="index" @click="changeTab(item,index)">
        <p>{{item.name}}</p>
        <p>保费：{{item.value}}元</p>
      </div>
    </div>
    <div class="line">
      <img src="../assets/detail/line1.png" v-if="tabActive == 0"  alt="">
      <img src="../assets/detail/line2.png" v-if="tabActive == 1" alt="">
      <img src="../assets/detail/line3.png" v-if="tabActive == 2" alt="">
    </div>
    <img src="../assets/detail/detail3.png" alt="" />
    <img src="../assets/detail/detail4.png" alt="" />
    <div class="footer">
      <div class="read">
        <img
          src="../assets/detail/checkbox.png"
          alt=""
          v-if="!isRead"
          @click="isRead = !isRead"
        />
        <img
          src="../assets/detail/checkbox-active.png"
          alt=""
          v-else
          @click="isRead = !isRead"
        />
        <p>
          我已阅读<span @click="pdf('property')">《财产一切险条款》</span
          ><span @click="pdf('common')">《公共责任保险条款》</span
          ><span @click="pdf('employer')">《雇主责任险（2016版）条款》</span>
        </p>
      </div>
      <div class="bottom">
        <p class="price">价格：￥<span>{{price}}</span></p>
        <p class="btn" @click="submit">立即投保</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isRead: false,
      tabList:[
        {
          name:'方案一',
          value:'2200'
        },
        {
          name:'方案二',
          value:'4000'
        },
        {
          name:'方案三',
          value:'7500'
        },
      ],
      tabActive:0,
      price:'2200'
    };
  },
  async created() {
    document.title = "轻住集团酒店保险";
    sessionStorage.clear();
  },
  methods: {
    submit(){
      if(this.isRead){
        this.$router.push({
        path:'/information',
        query:{
          price:this.price,
          plan:parseInt(this.tabActive)+1
        }
      })
      }else{
        this.$toast('请先阅读相关资料')
      }
      
    },
    //切换方案
    changeTab(item,index){
      this.tabActive = index;
      this.price = item.value;
    },
    //跳转pdf
    pdf(type) {
      this.$router.push({
        path: "/pdf",
        query: {
          type: type,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.detail {
  padding-bottom: 120px;
  background: #014EF4;
}
.line{
  margin-top: 5px;
  margin-bottom: 23px;
  img{
    width: 95%;
    margin: 0 auto;
  }
}
.tab {
  width: 84%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
  
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    p:first-child {
      font-size: 17px;
      font-weight: bold;
      line-height: 24px;
    }
    p:last-child {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .active{
      color: #f4d301;
  }
  .no-active{
      color: #769CF0;
  }
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: #fff;

  padding: 10px 10px 15px;
  .read {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    img {
      width: 14px;
      height: 15px;
      margin-top: 2px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-left: 6px;
      span {
        color: #569cff;
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .price {
    font-size: 15px;
    line-height: 21px;
    font-weight: bold;
    color: $primary-color;
    span {
      font-size: 20px;
    }
  }
  .btn {
    width: 116px;
    height: 45px;
    background: $primary-color;
    border-radius: 24px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>